/* global gtag */

import * as jsUtils from "@/utils/jsUtils.js";

export const gtagMixin = {
  methods: {
    trackEvent(
      eventName,
      actionType = "",
      project,
      value = 1,
      additionalParams = {}
    ) {
      const user = jsUtils.getUserInfo();
      const clientID = localStorage.getItem("clientID")
        ? localStorage.getItem("clientID")
        : user.clients[0].ClientId;

      const clientName =
        user.Clients.find((c) => c.ClientId == clientID).ClientName || null;
      const baseEventData = {
        date: new Date().toISOString().split("T")[0],
        account: user.AccountName,
        client: clientName,
        project: project.SurveyName,
        project_id: project.SurveyID,
        product_type: project.SurveyTypeName,
        encrypted_user_ID: user.EncrypteduserID,
        user_email: user.EmailId,
        action_type: actionType,
        value: value,
      };

      const eventData = { ...baseEventData, ...additionalParams };

      if (typeof gtag === "function") {
        gtag("event", eventName, eventData);
      }
    },
  },
};
