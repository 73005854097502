<template>
  <div class="project-item">
    <a-dropdown :trigger="['click']">
      <a class="project-item__dropdown-btn" @click.prevent>
        <font-awesome-icon icon="ellipsis-v" />
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item key="0">
            <a-button
              type="link"
              class="flex items-center"
              @click="downloadExcel"
            :disabled="!project.excelFileURL"
            >
              <span class="ant-btn-icon-wrapper">
                 <font-awesome-icon icon="download" />
              </span>
              Download data
            </a-button>
          </a-menu-item>
          <a-menu-item key="1">
            <a-button
              type="link"
              class="flex items-center"
              @click="$emit('delete-project')"
            >
              <span class="ant-btn-icon-wrapper">
                <font-awesome-icon icon="trash-alt" />
              </span>
              Delete
            </a-button></a-menu-item
          >
        </a-menu>
      </template>
    </a-dropdown>
    <div class="project-item__inner">
      <div class="project-item__image project-item__image--contain">
        <div class="project-item__action" v-if="project.pptFileURL">
          <a-button
            type="primary"
            @click="downloadPPT"
            :disabled="!project.pptFileURL"
            >Download</a-button
          >
          <a-button type="primary" @click="$emit('modify-project')"
            >Modify</a-button
          >
          <a-button type="primary" @click="$emit('reset-project')"
            >Restart</a-button
          >
        </div>
        <div class="project-item__action" v-else>
          <a-button type="primary" @click="$emit('modify-project')"
            >Configure</a-button
          >
        </div>
        <img :src="project.logoURL" alt="Map Maker" v-if="project.logoURL" />
        <img
          src="/images/mapMakerSample.png"
          alt="Map Maker"
          v-else
          class="blend-mode-darken"
        />
      </div>
      <div class="project-item__info">
        <h3>{{ project.projectNumber }}</h3>
        <div class="flex-1">
          <h2>{{ project.projectName }}</h2>
          <div class="project-item__info-tags">
            <span v-for="brand in project.brands" :key="brand">{{
              brand
            }}</span>
            <span>{{ project.category }}</span>
            <span>{{ project.market }}</span>
          </div>
        </div>
        <div class="project-item__info-author">
          <i>
            <icon-base
              :width="18"
              :height="18"
              :viewBox1="24"
              :viewBox2="24"
              iconName="calendar"
              iconColor="transparent"
              iconStroke="#7e7e7e"
              ><icon-calendar /></icon-base
          ></i>
          <div>
            <span>{{ project.dateFielded }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";

export default {
  name: "ProductItem",
  components: {
    IconBase,
    IconCalendar,
  },
  props: {
    project: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    downloadPPT() {
      if (this.project.pptFileURL) {
        window.open(this.project.pptFileURL);
      }
    },
      downloadExcel() {
      if (this.project.excelFileURL) {
        window.open(this.project.excelFileURL);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.project-item {
  position: relative;
  text-align: left;
  border-bottom: 1px solid #dbdbdb;
  height: 100%;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--white-100, #fff);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.25s ease;

  &:hover {
    border-color: var(--red);
    .project-item {
      &__action {
        opacity: 1;
        span {
          transform: translateY(0);
        }
      }
    }
  }

  &--disable-click {
    &:hover {
      border-color: rgba(0, 0, 0, 0.1);
    }
    .project-item__inner {
      cursor: default;
      .project-item {
        &__action {
          display: none;
        }
      }
    }
  }

  &__inner {
    padding: 20px 20px 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__action {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    z-index: 1;
    opacity: 0;
    transition: all 0.25s ease;
    &:before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(0deg, #ffffff 50%, #f6f6f6 100%);
      z-index: -1;
      opacity: 0.95;
    }
    span {
      transform: translateY(-10px);
      transition: all 0.25s ease;
    }
    .ant-btn {
      min-width: 130px;
    }
  }
  &__dropdown-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 3;
    cursor: pointer;
    background: #fff;
    padding: 2px 5px;
    border-radius: 4px;
    box-shadow: 0 0px 15px 1px rgba(0, 0, 0, 0.2);
  }
  &__image {
    border-radius: 8px;
    margin-bottom: 18px;
    padding-bottom: 60.95%;
    position: relative;
    background: #f6f6f6;
    overflow: hidden;
    &:after {
      border-radius: 6px;
      position: absolute;
      inset: 0;
      content: "";
      background: rgba(0, 0, 0, 0.6);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }
    img {
      max-width: 100%;
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &--contain {
      img {
        object-fit: contain;
        max-width: 80%;
        max-height: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    h2 {
      color: var(--dark);
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.4px;
      margin-bottom: 13px;
      flex: 1;
    }
    h3 {
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__info-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    span {
      border-radius: 3px;
      background: #f5f5f5;
      font-size: 12px;
      padding: 2px 7px;
      display: block;
      white-space: nowrap;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
    }
  }
  &__info-author {
    display: flex;
    align-items: center;
    margin-top: 30px;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    i {
      width: 34px;
      min-width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background: #e8e8e8;
      ::v-deep svg {
        width: 24px;
      }
    }
    span {
      display: block;
      font-size: 13px;
      color: #686868;
      line-height: normal;
      strong {
        font-size: 14px;
        font-weight: 400;
        color: #000;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

::v-deep .ant-dropdown-menu-item {
  padding: 0;
  .ant-btn {
    padding: 5px 12px;
    width: 100%;
  }
}
::v-deep .ant-btn-icon-wrapper {
  min-width: 20px;
  display: inline-flex;
  align-items: center;
}
</style>
