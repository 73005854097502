<template>
  <div class="active-shelf-tools">
    <div class="flex items-center active-shelf-tools__slider">
      <span>Zoom Out</span>
      <a-slider
        v-model="localSizeDivider"
        :min="1"
        :max="defaultSizeDivider"
        :step="0.001"
        :tooltipVisible="false"
        :reverse="true"
      />
      <span>Zoom In</span>
    </div>
    <div class="flex gap-10">
      <div
        class="active-shelf-tools__deep-dive"
        :disabled="!hasDeepDiveProduct"
      >
        <label>Show deep dive products</label>
        <a-switch v-model="inDeepDiveProducts" />
      </div>
      <a-button
        type="dark"
        @click="$emit('showRedistributeModal', true)"
        class="mr-20"
      >
        <a-icon type="column-width" /> Redistribute
      </a-button>
      <a-button type="dark" @click="customizeShelfModal = true" class="mr-20">
        <a-icon type="highlight" />
        Customize
      </a-button>
      <a-dropdown :trigger="['click']" class="mr-20">
        <a-button type="dark" class="small-ant-dropdown-trigger" @click.prevent
          ><font-awesome-icon icon="ellipsis-h" />
        </a-button>
        <template #overlay>
          <a-menu class="dark-dropdown-menu">
            <a-menu-item key="0">
              <a @click="uploadBulkImages">Upload product images</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a @click="addNewProduct">Add new product</a>
            </a-menu-item>
            <!-- <a-menu-item key="2">
              <a @click="changeShelfOrder('sort')">Reverse row order</a>
            </a-menu-item>
            <a-menu-item key="3">
              <a @click="changeShelfOrder('swap')">Bays to rows</a>
            </a-menu-item> -->
            <a-menu-item key="2" v-if="activeShelfIndex !== 0">
              <a @click="deleteShelf"> Delete Test {{ activeShelfIndex }}</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-button
        type="dark"
        @click="$emit('create-shelf-image')"
        class="ant-btn--confirm"
        :disable="disablePreviewShelfImg"
        ><font-awesome-icon icon="eye" />
        <span v-if="activeShelfIndex === 0">Preview Control</span>
        <span v-else>Preview Test {{ activeShelfIndex }}</span>
      </a-button>
    </div>

    <div
      class="customize-shelf-modal"
      ref="customizeShelfModal"
      v-if="customizeShelfModal"
    >
      <div
        class="customize-shelf-modal__title flex items-center justify-between"
        @mousedown="startDrag"
      >
        <h2 class="flex items-center gap-5">
          Customize Shelf

          <a-tooltip overlayClassName="ant-tooltip--step-nav">
            <template slot="title">
              Move this modal to where it suits you best.
            </template>
            <a-icon type="info-circle" class="cursor-pointer" />
          </a-tooltip>
        </h2>
        <div @click="customizeShelfModal = false" class="cursor-pointer flex">
          <icon-base
            :width="18"
            :height="18"
            :viewBox1="1024"
            :viewBox2="1024"
            iconName="close"
            iconColor="#fff"
            iconStroke="transparent"
            ><icon-close
          /></icon-base>
        </div>
      </div>
      <div class="customize-shelf-modal__inner">
        <ul class="design-tabs flex items-center">
          <li
            @click="activeDesignTab = 0"
            :class="{ 'is-active': activeDesignTab === 0 }"
          >
            Background
          </li>
          <li
            :class="{ 'is-active': activeDesignTab === 1 }"
            @click="activeDesignTab = 1"
          >
            Dots Pattern
          </li>
        </ul>

        <color-picker
          v-model="shelfBcgColor"
          v-if="activeDesignTab === 0"
        ></color-picker>
        <div v-else>
          <color-picker v-model="dotsBcgColor"></color-picker>
          <div class="mt-20 slider">
            <label> Dots Spacing </label>
            <div class="flex items-center justify-center">
              <a-slider
                v-model="setDotsSpace"
                :min="0"
                :max="50"
                class="flex-1 ant-slider-dark"
                :tooltipVisible="false"
              />
              <span class="ml-5">{{ dotsSpace }}px</span>
            </div>
          </div>
          <div class="mt-10 slider">
            <label> Dots Size </label>
            <div class="flex items-center justify-center">
              <a-slider
                v-model="setDotsSize"
                :min="0"
                :max="50"
                class="flex-1 ant-slider-dark"
                :tooltipVisible="false"
              />
              <span class="ml-5">{{ dotsSize }}px</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from "vue-color";
import IconBase from "@/components/general/IconBase.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  name: "ActiveShelfTools",
  components: {
    "color-picker": Chrome,
    IconBase,
    IconClose,
  },
  props: {
    loading: {
      type: Boolean,
    },
    sizeDivider: {
      type: Number,
      default() {
        return 1;
      },
    },
    activeShelfIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    defaultSizeDivider: {
      type: Number,
      default() {
        return 1;
      },
    },
    disablePreviewShelfImg: {
      type: Boolean,
    },
    showInDeepDiveProducts: {
      type: Boolean,
    },
    hasDeepDiveProduct: {
      type: Boolean,
    },
    shelfColor: {
      type: [Object, String],
    },
    dotsColor: {
      type: [Object, String],
    },
    dotsSpace: {
      type: Number,
    },
    dotsSize: {
      type: Number,
    },
  },
  data() {
    return {
      customizeShelfModal: false,
      activeDesignTab: 0,
      isDragging: false,
      offset: { x: 0, y: 0 },
      spaceAdjustment: 1,
    };
  },
  computed: {
    localSizeDivider: {
      get() {
        return this.sizeDivider;
      },
      set(newValue) {
        this.$emit("update-size-divider", newValue);
      },
    },
    inDeepDiveProducts: {
      get() {
        return this.showInDeepDiveProducts;
      },
      set(newValue) {
        this.$emit("show-in-deep-dive", newValue);
      },
    },
    shelfBcgColor: {
      get() {
        return this.shelfColor;
      },
      set(newValue) {
        this.$emit("set-color", newValue.hex);
      },
    },
    dotsBcgColor: {
      get() {
        return this.dotsColor;
      },
      set(newValue) {
        this.$emit("set-dots-color", newValue.hex);
      },
    },
    setDotsSpace: {
      get() {
        return this.dotsSpace;
      },
      set(newValue) {
        this.$emit("set-dots-space", newValue);
      },
    },
    setDotsSize: {
      get() {
        return this.dotsSize;
      },
      set(newValue) {
        this.$emit("set-dots-size", newValue);
      },
    },
  },
  watch: {},
  methods: {
    deleteShelf() {
      let _this = this;
      this.$confirm({
        title: `Delete Test ${_this.activeShelfIndex}`,
        class: "ant-modal--dark",
        content: () => (
          <div>
            Are you sure you want to delete Test {_this.activeShelfIndex}?
          </div>
        ),
        onOk() {
          _this.$emit("delete-shelf", _this.activeShelfIndex);
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    uploadBulkImages() {
      this.$emit("upload-bulk-images", {
        fileType: ".zip",
        fileLabel: "ZIP",
      });
    },
    addNewProduct() {
      this.$emit("add-new-product");
    },
    changeShelfOrder(type) {
      this.$emit("change-shelf-order", type);
    },
    startDrag(event) {
      this.isDragging = true;
      this.offset.x = event.clientX - this.$refs.customizeShelfModal.offsetLeft;
      this.offset.y = event.clientY - this.$refs.customizeShelfModal.offsetTop;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        const div = this.$refs.customizeShelfModal;
        div.style.left = `${event.clientX - this.offset.x}px`;
        div.style.top = `${event.clientY - this.offset.y}px`;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
  },
};
</script>

<style lang="scss" scoped>
.active-shelf-tools {
  display: flex;
  padding: 0 20px;
  margin-bottom: 15px;
  margin-top: 15px;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  z-index: 99;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .ant-btn {
    &--confirm {
      min-width: 170px;
    }
    svg {
      margin-right: 8px;
    }
    &[disable] {
      opacity: 0.5;
      cursor: default;
      background: #1890ff !important;
    }
    &.ant-btn-dark {
      background: #3c3d3e;
      border-color: #4c4d4e;
      &:hover {
        background: #404141;
      }
    }
  }

  &__color {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    label {
      color: white;
      font-size: 13px;
      margin-right: 6px;
    }
    i {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: block;
      border: 3px solid #3e3f40;
    }
  }

  &__deep-dive {
    display: flex;
    align-items: center;
    margin-right: 20px;
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
    label {
      color: white;
      font-size: 13px;
      margin-right: 6px;
    }
    ::v-deep .ant-switch {
      background: #373839;
      border: 1px solid #4c4d4e;
      &.ant-switch-checked {
        background: var(--blue);
      }
    }
  }

  &__slider {
    color: #d9d9d9;
    font-size: 13px;
    gap: 10px;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    bottom: 15px;
    background: rgba(26, 27, 29, 0.9);
    padding: 0 11px;
    border-radius: 8px;

    ::v-deep .ant-slider {
      width: 140px;
      margin-right: 0;
      .ant-slider-rail {
        height: 2px;
        background: var(--blue);
      }
      .ant-slider-track {
        height: 2px;
        background-color: #464646;
      }
      .ant-slider-step {
        height: 2px;
      }

      .ant-slider-handle {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: solid 2px #afafaf;
      }
    }
  }
}
</style>

<style lang="scss">
.customize-shelf-modal {
  background-color: #262729;
  position: fixed;
  left: 50%;
  top: 150px;
  transform: translateX(-50%);
  z-index: 999;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.3);
  user-select: none;
  width: 400px;
  &__title {
    cursor: move;
    padding: 16px 24px;
    border-bottom: 1px solid #4b4e52;
    h2 {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
  &__content {
    padding: 20px;
  }
  &__inner {
    padding: 24px;
    color: #fff;
    font-size: 14px;
    .vc-chrome {
      width: 100%;
      box-shadow: none;
      border-radius: 6px;
      .vc-chrome-body {
        background-color: #262729;
        padding: 16px 0px 0;
        .vc-chrome-controls {
          .vc-chrome-color-wrap,
          .vc-chrome-alpha-wrap {
            display: none;
          }
        }
      }
      .vc-chrome-saturation-wrap {
        border-radius: 6px 6px 0 0;
      }
      .vc-chrome-fields-wrap {
        padding-top: 6px;
      }
      .vc-chrome-toggle-btn,
      .vc-input__label {
        display: none;
      }
      .vc-input__input {
        color: #ffffff;
        box-shadow: none;
        background: #313131;
        height: 24px;
      }
    }
    .design-tabs {
      color: #fff;
      width: 100%;
      padding: 0;
      margin: 0 0 20px;
      li {
        flex: 1;
        text-align: center;
        font-size: 13px;
        border-bottom: 2px solid transparent;
        padding-bottom: 8px;
        cursor: pointer;
        &.is-active {
          border-color: var(--purpleLight);
        }
      }
    }
    .slider {
      label {
        color: #fff;
        display: block;
        margin-bottom: -5px;
        font-size: 12px;
      }
      span {
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
</style>
