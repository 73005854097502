<template>
  <draggable
    class="dragArea"
    :class="{
      empty: items.length === 0,
      startDragging: startDragging,
    }"
    tag="ul"
    :list="items"
    :group="{ name: 'g1', pull: true, put: true }"
    handle=".js-draggable-handle"
    @change="onDragChange"
    @end="$emit('end-dragging')"
    @start="$emit('start-dragging')"
  >
    <li v-for="item in items" :key="item.upc" class="draggable-item">
      <div
        class="draggable-item-content"
        :style="{
          'padding-bottom': `${shelfPaddingBottom / sizeDivider}px`,
          'background-size': `auto ${shelfPaddingBottom / sizeDivider}px`,
          marginLeft: `${item.spaceLeft / sizeDivider}px`,
          marginRight: `${item.spaceRight / sizeDivider}px`,
        }"
        :class="{
          'is-active':
            selectedProduct && item.upc === selectedProduct.upc && !loading,
          'is-deep-dive': showInDeepDiveProducts && item.inDeepDive,
          'no-image': !item.thumbProductURL && !item.thumb_rotated_image_url,
          'hide-price': hidePrice,
        }"
      >
        <div
          class="shelf-builder__item-resize"
          v-if="selectedProduct && selectedProduct.upc === item.upc"
          :style="{
            right: `${-4 / sizeDivider}px`,
            top: `${-11 / sizeDivider}px`,
            borderTopWidth: `${18 / sizeDivider}px`,
            borderBottomWidth: `${21 / sizeDivider}px`,
            borderLeftWidth: `${20 / sizeDivider}px`,
          }"
          @mousedown="$emit('init-resize', $event)"
        ></div>

        <div
          @click.stop="$emit('item-clicked', item)"
          class="shelf-builder__item-image js-draggable-handle"
          :ref="`nestedProductRef_${item.upc}`"
          :data-upc="item.upc"
        >
          <div
            class="shelf-builder__item-image-row"
            v-for="(imgRow, imgRowIndex) in item.heightFacing"
            :key="imgRowIndex"
          >
            <img
              v-for="(img, imgIndex) in item.widthFacing"
              :key="imgIndex"
              :src="`${
                item.thumb_rotated_image_url || item.thumbProductURL
              }?time=${timestamp}`"
              :width="item.productWidth / sizeDivider"
              :height="item.productHeight / sizeDivider"
              v-show="item.thumbProductURL || item.thumb_rotated_image_url"
              crossOrigin="anonymous"
            />
          </div>
        </div>

        <div
          class="shelf-builder__item-price"
          :style="{
            fontSize: `${13 / sizeDivider}px`,
            bottom: `${(shelfPaddingBottom / 2 - 12) / sizeDivider}px`,
          }"
          v-if="item.showPrice && item.price !== 0 && item.price"
        >
          {{ currencySymbol || "" }}{{ item.price }}
        </div>
      </div>
    </li>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "nested-draggable",
  components: {
    draggable,
  },
  data() {
    return {
      selectedProducts: [],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    sizeDivider: {
      type: Number,
    },
    timestamp: {
      type: Number,
    },
    shelfPaddingBottom: {
      type: Number,
    },
    currencySymbol: {
      type: String,
    },
    selectedProduct: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    showInDeepDiveProducts: {
      type: Boolean,
    },
    startDragging: {
      type: Boolean,
    },
    hidePrice: {
      type: Boolean,
    },
  },
  methods: {
    onDragChange(evt) {
      this.$emit("calculate-shelf-layout");
      if (evt.added && evt.added.element) {
        const addedProduct = evt.added.element;
        if (addedProduct.stack && addedProduct.stack.length > 0) {
          this.$emit("product-stacked", addedProduct);
        } else if (!addedProduct.stack) {
          addedProduct.stack = [];
        }
      }
    },
    isMultiSelected(product) {
      return (
        this.selectedProducts.includes(product) &&
        this.selectedProducts.length > 1
      );
    },
  },
};
</script>

<style scoped>
.dragArea {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.empty {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='800' viewBox='0 0 32 32'%3E%3Cpath d='M28.608 11.246 16 2.614 3.392 11.246 16 19.877l12.608-8.631zM16 21.803 4.871 14.465 3.392 16 16 24.631 28.608 16l-1.499-1.568L16 21.803zm0 4.756L4.871 19.221l-1.479 1.535L16 29.387l12.608-8.631-1.499-1.568L16 26.559z'/%3E%3C/svg%3E")
      center center rgba(255, 255, 255, 0.3);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    &.startDragging {
      height: 50%;
    }
  }
}
.draggable-item {
  list-style-type: none;
  position: relative;
}
.draggable-item-content {
  position: relative;
  background: url("/images/shelf-price-bcg.jpg") repeat-x;
  background-position: bottom;
  &.is-active,
  &:hover {
    .shelf-builder__item-image {
      &::after {
        opacity: 1;
      }
    }
    .shelf-builder__item-resize {
      opacity: 1;
      pointer-events: all;
    }
  }
  &.no-image {
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' opacity='0.5' d='M7.828 5l-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.503.503 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.506 1.506 0 0 0 .677.163 1.403 1.403 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5zm-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5zm5.646 13.854l.707-.707-20-20-.707.707z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
      center no-repeat #d17903;
    background-size: 60% 60%;
  }
  &:after {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    inset: 0;
    content: "";
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
    z-index: 1;
  }
  &.is-deep-dive {
    &::after {
      opacity: 0.65 !important;
      background: var(--blue);
    }
  }

  &.hide-price {
    .shelf-builder__item-price {
      display: none;
    }
  }
}

.shelf-builder__item-price {
  position: absolute;
  left: 50%;
  bottom: -31px;
  text-align: center;
  z-index: 2;
  background: #f4f4f4;
  color: #000;
  padding: 1px 2px;
  font-weight: 500;
  line-height: normal;
  transform: translateX(-50%);
  border: 1px solid #333;
}

.shelf-builder__item-resize {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  cursor: nesw-resize;
  right: -4px;
  top: -11px;
  border-top: 18px solid transparent;
  border-bottom: 21px solid transparent;
  z-index: 1;
  border-left: 20px solid WHITE;
  transform: rotate(-43deg);
  z-index: 10;
}

.shelf-builder__item-image {
  position: relative;
  cursor: pointer;
  &:after {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    inset: 0;
    content: "";
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
    z-index: 2;
  }
}

.shelf-builder__item-image-row {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
</style>
