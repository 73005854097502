<template>
  <div class="quota-list">
    <div class="quota-list__data-info" v-if="loading">
      <Loader text="Loading..."></Loader>
    </div>
    <div v-else>
      <div class="quota-list__table flex-1">
        <a-table
          tableLayout="auto"
          class="custom-table custom-table--remove-hover"
          :columns="columns"
          :row-key="(record, index) => `${record.surveyNumber}`"
          :data-source="quotaDetails.SurveyData"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <div
            slot="Status"
            slot-scope="record"
            class="input-group input-group--small"
          >
            <a-select
              style="width: 130px"
              option-filter-prop="children"
              :class="{
                'pointer-events-none':
                  activeUpdateStatus === record.surveyNumber,
              }"
              :default-value="getStatusName(record.surveyStatusCode)"
              @select="updateStatus($event, record)"
              :loading="activeUpdateStatus === record.surveyNumber"
              :key="`${record.surveyNumber}-${uniqueCounter}`"
            >
              <a-select-option
                v-for="status in statuses"
                :key="status.statusId"
                :value="status.statusId"
                :disabled="status.statusId == record.surveyStatusCode"
              >
                {{ status.statusName }}
              </a-select-option>
            </a-select>
          </div>

          <div
            slot="Tools"
            slot-scope="record"
            class="flex items-center justify-end gap-10"
          >
            <a-tooltip>
              <template slot="title"> Edit </template>
              <button
                class="text-btn text-btn--dark text-left"
                type="link"
                @click="editQuota(record)"
              >
                <font-awesome-icon icon="edit" />
              </button>
            </a-tooltip>
            <a-tooltip>
              <template slot="title"> Copy </template>
              <button
                class="text-btn text-btn--dark text-left flex items-center"
                type="link"
                @click="copy(record)"
                :class="{
                  'pointer-events-none':
                    activeCopyQuota === record.surveyNumber,
                }"
              >
                <Loader
                  class="small-loader space-0"
                  v-if="activeCopyQuota === record.surveyNumber"
                ></Loader>
                <font-awesome-icon v-else icon="copy" />
              </button>
            </a-tooltip>
            <a-tooltip>
              <template slot="title"> Delete </template>
              <button
                class="text-btn text-btn--dark text-left flex items-center"
                type="link"
                @click="deleteQuotaConfirm(record)"
                :class="{
                  'pointer-events-none':
                    activeDeleteQuota === record.surveyNumber,
                }"
              >
                <Loader
                  class="small-loader space-0"
                  v-if="activeDeleteQuota === record.surveyNumber"
                ></Loader>

                <font-awesome-icon v-else icon="trash-alt" />
              </button>
            </a-tooltip>
          </div>
        </a-table>
      </div>
    </div>
    <a-modal
      title="PO Entry"
      :visible="poModal"
      @cancel="(poModal = false), (po = null), uniqueCounter++"
      :footer="null"
      :width="400"
      centered
    >
      <p class="mb-20">
        All field costs must now be approved before field can commence. Please
        submit a PO via
        <a
          href="https://app.smartsheet.com/b/form/6a2fc8502c2946fa849aa2db327d6559"
          target="_blank"
          class="underline"
          >this link</a
        >
        with your estimated costs (sample * CPI, keeping in mind that CPI is in
        USD).<br />Once your request is approved, you will receive a unique PO
        ID by email that you can enter in the field below.
      </p>
      <div class="flex items-center position-r">
        <div class="input-group input-group--small mb-15 flex-1">
          <label
            >PO ID
            <a-tooltip>
              <template slot="title">
                To validate the PO, please enter a value and either click
                outside the field or press Enter.
              </template>
              <a-icon type="info-circle" /> </a-tooltip
          ></label>
          <a-input
            v-model.trim="po"
            placeholder="Enter PO ID"
            @blur="checkPo"
            @keyup.enter="checkPo"
          ></a-input>
        </div>
        <div class="position-a left-full mt-10 ml-5">
          <a-tooltip placement="top">
            <template slot="title"> PO is valid </template>
            <font-awesome-icon
              class="font-size-16 cursor-pointer"
              icon="check-circle"
              style="color: #01c101"
              v-if="
                this.lastValidPo === this.po &&
                this.po !== null &&
                this.lastValidPo !== null
              "
            />
          </a-tooltip>
          <Loader v-if="loadingCheckPo" class="small-loader"></Loader>
        </div>
      </div>
      <div class="flex justify-end gap-10">
        <a-button
          class="mt-20"
          type="default"
          @click="(poModal = false), (po = null), uniqueCounter++"
          >Close</a-button
        >
        <a-button
          class="mt-20"
          :loading="submitPOLoading"
          type="primary"
          @click="submitPO"
          :disabled="!po || lastValidPo !== po"
          >Submit</a-button
        >
      </div>
    </a-modal>
    <a-modal
      title="Quota Definition"
      :visible="editModal"
      @cancel="editModal = false"
      :footer="null"
      :width="800"
      centered
    >
      <EditQuotaModal
        :activeQuota="activeQuota"
        :surveyCells="surveyCells"
        v-if="editModal"
        @close="closeEditQuotaModal"
        @send-gtag="sendGtag('update')"
      ></EditQuotaModal>
    </a-modal>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
import EditQuotaModal from "@/components/general/EditQuotaModal.vue";
import { gtagMixin } from "@/mixins/gtagMixin";

const columns = [
  {
    title: "Cell",
    dataIndex: "surveyCellName",
  },
  {
    title: "Quota",
    dataIndex: "quotaName",
  },
  {
    title: "Status",
    scopedSlots: { customRender: "Status" },
  },
  {
    title: "Completes",
    dataIndex: "completes",
  },
  {
    title: "",
    scopedSlots: { customRender: "Tools" },
  },
];

export default {
  name: "ManageQuota",
  components: {
    Loader,
    EditQuotaModal,
  },
  props: {
    surveyId: {
      type: String,
    },
    activeProject: {
      type: Object,
    },
  },
  mixins: [gtagMixin],
  data() {
    return {
      loading: false,
      activeCopyQuota: null,
      quotaDetails: {
        SurveyData: [],
      },
      columns,
      pagination: {},
      statuses: [
        { statusId: 2, statusName: "Pending" },
        { statusId: 3, statusName: "Live" },
        { statusId: 4, statusName: "Complete" },
      ],
      poModal: false,
      activeUpdateStatus: false,
      po: null,
      activeQuota: null,
      uniqueCounter: 0,
      submitPOLoading: false,
      editModal: false,
      surveyCells: [],
      activeDeleteQuota: null,
      loadingCheckPo: false,
      lastValidPo: null,
      checkedPoValue: null,
    };
  },
  computed: {},
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },

    getDetails() {
      this.loading = true;
      const data = {
        wsName: "GetSurveyFulcrumData",
        data: {
          surveyId: this.surveyId,
        },
      };

      const getDetails = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getDetails.then((data) => {
        if (!data.success) {
          this.$notification["info"]({
            message: "Info",
            description: "There is no quota available to proceed.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.$emit("close");
        } else {
          this.quotaDetails = data.surveyFulcrumData;
          this.surveyCells = data.surveyCells;
          const pagination = { ...this.pagination };
          pagination.total = this.quotaDetails.SurveyData.length;
          pagination.hideOnSinglePage = true;
          pagination.pageSize = 7;
          this.pagination = pagination;
        }
        this.loading = false;
      });
    },

    getStatusName(statusId) {
      const status = this.statuses.find((s) => s.statusId === Number(statusId));
      return status ? status.statusName : "Unknown";
    },
    editQuota(record) {
      this.editModal = true;
      this.activeQuota = record;
    },
    updateStatus(newStatus, record) {
      this.activeUpdateStatus = record.surveyNumber;
      const data = {
        wsName: "SetStatusOnFulcrum",
        data: {
          fulcrumNumber: record.surveyNumber,
          projectID: this.quotaDetails.projectId,
          surveyCellName: record.surveyCellName,
          quotaName: record.quotaName,
          status: newStatus,
        },
      };

      const updateStatus = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      updateStatus.then((data) => {
        if (!data.success) {
          if (data.IsRequiedPO) {
            this.activeQuota = record;
            this.poModal = true;
          } else {
            this.$notification["error"]({
              message: "Error!",
              description:
                "Something went wrong. Please try again or contact support",
              placement: "bottomLeft",
              duration: 4,
            });
          }
        } else {
          this.$notification["success"]({
            message: "Well done!",
            description: "Status updated successfully.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.getDetails();
        }
        this.activeUpdateStatus = null;
      });
    },
    copy(record) {
      this.activeCopyQuota = record.surveyNumber;
      const data = {
        wsName: "CopyQuota",
        data: {
          fulcrumNumber: record.surveyNumber,
          projectID: this.quotaDetails.projectId,
          SurveyGizmoID: this.quotaDetails.surveyGizmoID,
          surveyID: this.quotaDetails.surveyID,
        },
      };

      const copy = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      copy.then((data) => {
        if (data.success) {
          this.getDetails();
          this.sendGtag("copy");
        } else {
          this.$notification["error"]({
            message: "Error!",
            description: "Something went wrong, please try again.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
        this.activeCopyQuota = null;
      });
    },
    sendGtag(actionType) {
      this.trackEvent(
        "fulcrum_quotas_update_copy",
        actionType,
        this.activeProject,
        1
      );
    },
    deleteQuotaConfirm(record) {
      let _this = this;
      this.$confirm({
        title: `Delete Quota`,
        content: () => <div>Are you sure you want to delete this quota?</div>,
        onOk() {
          _this.deleteQuota(record);
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    deleteQuota(record) {
      this.activeDeleteQuota = record.surveyNumber;
      const data = {
        wsName: "DeleteQuota",
        data: {
          fulcrumNumber: record.surveyNumber,
          surveyID: this.quotaDetails.surveyID,
        },
      };

      const copy = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      copy.then((data) => {
        if (data.success) {
          this.getDetails();
        } else {
          this.$notification["error"]({
            message: "Error!",
            description: "Something went wrong, please try again.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
        this.activeDeleteQuota = null;
      });
    },
    submitPO() {
      this.submitPOLoading = true;
      const data = {
        wsName: "CheckPOApproved",
        data: {
          fulcrumNumber: this.activeQuota.surveyNumber,
          projectID: this.quotaDetails.projectId,
          SurveyName: this.activeQuota.quotaName,
          poID: this.po,
        },
      };

      const submitPO = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      submitPO.then((data) => {
        if (data.success) {
          this.$notification["success"]({
            message: "Well done!",
            description: "Status updated successfully.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.getDetails();
          this.poModal = false;
          this.po = null;
        } else {
          this.$notification["error"]({
            message: "Error!",
            description: data.message,
            placement: "bottomLeft",
            duration: 8,
          });
        }
        this.submitPOLoading = false;
      });
    },
    closeEditQuotaModal(type) {
      if (type === "edit") {
        this.getDetails();
      }
      this.editModal = false;
    },
    checkPo() {
      if (
        this.lastValidPo === this.po ||
        this.po === null ||
        this.po === "" ||
        this.checkedPoValue === this.po
      )
        return;
      this.loadingCheckPo = true;
      const model = {
        wsName: "ValidatePoValue",
        params: {
          poValue: this.po,
        },
      };

      const checkPoValue = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      checkPoValue.then((data) => {
        this.loadingCheckPo = false;
        if (!data.success) {
          this.$notification["error"]({
            message: "Error!",
            description: data.message,
            placement: "bottomLeft",
            duration: 10,
          });
        } else {
          this.lastValidPo = this.po;
        }
        this.checkedPoValue = this.po;
      });
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style lang="scss" scoped>
.quota-list {
  position: relative;
  min-height: 566px;
  &__data-info {
    min-height: 566px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
