<template>
  <div class="quota-list">
    <div class="quota-list__data-info" v-if="loading">
      <Loader text="Loading..."></Loader>
    </div>
    <div
      v-else-if="
        quotaDetails.completes ||
        quotaDetails.lOI ||
        quotaDetails.totalEntrants ||
        quotaDetails.totalCost
      "
    >
      <div class="flex justify-between items-center mb-25">
        <div class="quota-list__title">
          <Skeleton v-if="loading"></Skeleton>
          <h2 v-else class="section-title mb-0">
            {{ quotaDetails.surveyName }}
          </h2>
        </div>
        <div class="flex items-center gap-5">
          <div class="input-group input-group--small" :disabled="loading">
            <div
              class="position-r search-field"
              :class="{ focus: searchActive }"
            >
              <a-input
                placeholder="Search"
                style="width: 240px"
                v-model="searchParameters"
                @focus="searchActive = true"
                @blur="searchActive = false"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="close-circle"
                  v-show="searchParameters != ''"
                  @click="searchParameters = ''"
              /></a-input>
              <icon-base
                class="search-icon"
                :width="20"
                :height="20"
                :viewBox1="64"
                :viewBox2="64"
                iconName="searchThin"
                iconColor="transparent"
                iconStroke="#000"
                ><icon-search-thin
              /></icon-base>
            </div>
          </div>
          <a-dropdown
            :visible="dropdownVisible"
            :trigger="['click']"
            @visibleChange="handleVisibleChange"
            overlayClassName="filters-dropdown"
            placement="bottomRight"
          >
            <a
              class="filters-dropdown-cta"
              :class="{ 'is-active': dropdownVisible }"
              @click="dropdownVisible = true"
              :disabled="filterOptions.length === 0"
            >
              <i v-if="selectedFilters.length > 0"></i>
              <icon-base
                :width="24"
                :height="24"
                :viewBox1="24"
                :viewBox2="24"
                iconName="filters"
                iconColor="transparent"
                iconStroke="#1C1C1C"
                ><icon-filters
              /></icon-base>
              Filters
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item key="0">
                  <div class="input-group input-group--small">
                    <label>Select Filters</label
                    ><a-select
                      class="w-full"
                      mode="tags"
                      style="width: 240px"
                      @change="selectFilter"
                      v-model="selectedFilters"
                    >
                      <a-select-opt-group
                        :label="category.label"
                        v-for="category in filterOptions"
                        :key="category.label"
                      >
                        <a-select-option
                          :value="category.label + ':' + item.value"
                          v-for="item in category.options"
                          :key="item.value"
                          >{{ item.label }}</a-select-option
                        >
                      </a-select-opt-group>
                    </a-select>
                  </div>
                </a-menu-item>
                <a-menu-item key="1">
                  <div class="flex justify-end gap-15 mt-5 mb-5">
                    <a-button
                      type="link"
                      v-if="selectedFilters.length > 0"
                      @click="(selectedFilters = []), (dropdownVisible = false)"
                      >Reset Filters</a-button
                    >
                    <a-button type="primary" @click="dropdownVisible = false"
                      >Close</a-button
                    >
                  </div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div class="quota-list__update-info" v-if="quotaDetails.message">
        <a-icon type="info-circle" />
        <p>
          After quotas have been updated, Alchemer still requires
          user-interaction to confirm the changes. Please
          <a :href="quotaDetails.message" class="underline" target="_blank"
            >click here</a
          >, open a quota and then resave.
        </p>
      </div>
      <div class="flex mb-25">
        <div class="quota-list__statistics">
          <div class="quota-list__statistics-item">
            <div class="quota-list__statistics-item-loader" v-if="loading">
              <i class="circle-loader"></i>
            </div>
            <span v-else>{{ completes }}</span>
            <h2>Completes</h2>
          </div>
          <div
            class="quota-list__statistics-item quota-list__statistics-item--1"
          >
            <div class="quota-list__statistics-item-loader" v-if="loading">
              <i class="circle-loader"></i>
            </div>
            <span v-else>{{ quotaDetails.lOI }}</span>
            <h2>LOI</h2>
          </div>
          <div
            class="quota-list__statistics-item quota-list__statistics-item--2"
          >
            <div class="quota-list__statistics-item-loader" v-if="loading">
              <i class="circle-loader"></i>
            </div>
            <span v-else>{{ quotaDetails.totalEntrants }}</span>
            <h2>Total Entrants</h2>
          </div>
          <div
            class="quota-list__statistics-item quota-list__statistics-item--3"
          >
            <div class="quota-list__statistics-item-loader" v-if="loading">
              <i class="circle-loader"></i>
            </div>
            <span v-else>{{ quotaDetails.totalCost || "-" }}</span>
            <h2>Total Cost</h2>
          </div>
        </div>
        <div class="quota-list__table flex-1">
          <a-table
            tableLayout="auto"
            class="custom-table"
            :columns="columns"
            :row-key="
              (record, index) =>
                `${index}-${record.quotaName}-${record.status}-${record.value}`
            "
            :data-source="searchQuotaList"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
          >
            <span slot="Progress" slot-scope="record">
              <a-progress :percent="parseProgress(record.percentage)" />
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <div v-else class="quota-list__data-info">
      <DataInfo text="No data found..." :border0="true"></DataInfo>
    </div>
    <div class="flex justify-between" v-if="!loading">
      <div class="flex gap-10">
        <a-button
          v-if="quotaDetails.manageQuota"
          :disabled="!quotaDetails.manageQuota"
          @click="manageSample"
        >
          Manage Sample
        </a-button>
        <a-button
          @click="
            $emit('upload-tabplan', {
              SurveyID: surveyId,
              SurveyTypeName: surveyType,
              SurveyName: surveyName,
            })
          "
          :disabled="
            !quotaDetails.completes &&
            !quotaDetails.lOI &&
            !quotaDetails.totalEntrants &&
            !quotaDetails.totalCost
          "
        >
          Upload Tabplan
        </a-button>
      </div>

      <div class="flex gap-10">
        <a-button
          type="primary"
          @click="downloadQuotasExcel"
          :loading="loadingExcel"
          :disabled="quotaDetails.quotaLists.length === 0"
        >
          Download Quotas
        </a-button>
        <a-button
          type="primary"
          @click="updateQuotas = true"
          :disabled="
            !quotaDetails.completes &&
            !quotaDetails.lOI &&
            !quotaDetails.totalEntrants &&
            !quotaDetails.totalCost
          "
        >
          Update Quotas
        </a-button>
      </div>
    </div>
    <div class="quota-list__upload" v-if="updateQuotas">
      <div class="quota-list__upload-content">
        <h2>Update Quotas</h2>
        <p class="mb-20">
          This feature is used to flex and/or update your survey's quotas.
          Please upload your excel file that you obtained via download within
          this module with the updates you've made to your limits/quotas. This
          will reflect in the Alchemer quotas.
        </p>
        <a-upload-dragger
          name="file"
          :multiple="false"
          accept=".xlsx,.xls,.csv"
          :showUploadList="false"
          :customRequest="addQuotaFile"
          ref="upload"
        >
          <div>
            <font-awesome-icon icon="file-upload" v-if="quotaFile === null" />
            <font-awesome-icon icon="check-circle" v-else />
            <p>Drop file here or click to upload</p>
          </div>
        </a-upload-dragger>
        <div class="gap-10 flex gap-10 justify-end mt-20">
          <a-button @click="(updateQuotas = false), (quotaFile = null)"
            >Cancel</a-button
          >
          <a-button
            type="primary"
            :loading="loadingUpload"
            @click="uploadQuota"
            :disabled="quotaFile === null"
            >Update</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import Skeleton from "@/components/general/Skeleton.vue";
import Loader from "@/components/general/Loader.vue";
import DataInfo from "@/components/general/DataInfo.vue";
import IconFilters from "@/components/icons/IconFilters.vue";
import { gtagMixin } from "@/mixins/gtagMixin";

const columns = [
  {
    title: "Quota name",
    dataIndex: "quotaName",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
  {
    title: "Progress",
    scopedSlots: { customRender: "Progress" },
  },
];

export default {
  name: "QuotaListModal",
  components: {
    IconBase,
    IconSearchThin,
    Skeleton,
    Loader,
    DataInfo,
    IconFilters,
  },
  props: {
    surveyId: {
      type: String,
    },
    surveyType: {
      type: String,
    },
    surveyName: {
      type: String,
    },
  },
  mixins: [gtagMixin],
  data() {
    return {
      searchParameters: "",
      searchActive: false,
      columns,
      loading: false,
      loadingExcel: false,
      loadingUpload: false,
      pagination: {
        pageSize: 8,
        current: 1,
        total: 0,
        hideOnSinglePage: true,
      },
      quotaDetails: {
        surveyName: "",
        completes: null,
        lOI: null,
        totalEntrants: null,
        totalCost: null,
        quotaLists: [],
      },
      quotaFile: null,
      updateQuotas: false,
      dropdownVisible: false,
      selectedFilters: [],
    };
  },
  computed: {
    searchQuotaList() {
      const searchParams = this.searchParameters.toLowerCase().split(" ");
      const selectedFilters = this.selectedFilters.map((filter) => {
        const [key, value] = filter.split(":");
        return { key: key.toLowerCase(), value: value.toLowerCase() };
      });

      return this.filterQuotas.filter((item) => {
        // Check if quotaName contains all search parameters
        const matchesSearch = searchParams.every((param) =>
          item.quotaName.toLowerCase().includes(param)
        );

        // Check if item filters match the selected filters
        const matchesFilters = selectedFilters.every((filter) => {
          if (item.filters && item.filters[filter.key]) {
            return item.filters[filter.key].toLowerCase() === filter.value;
          }
          return false;
        });

        return matchesSearch && matchesFilters;
      });
    },
    filterOptions() {
      if (Object.keys(this.quotaDetails.FilterList).length === 0) {
        return [];
      }

      return Object.keys(this.quotaDetails.FilterList).map((key) => {
        const uniqueValues = [
          ...new Set(
            this.quotaDetails.FilterList[key].map((value) => value.trim())
          ),
        ];
        return {
          label: key,
          options: uniqueValues.map((value) => ({
            value: value,
            label: value,
          })),
        };
      });
    },
    filterQuotas() {
      return this.quotaDetails.quotaLists.map((item) => {
        const parts = item.quotaName.split("_").map((part) => part.trim());
        if (parts.length >= 5) {
          return {
            ...item,
            filters: {
              cell: parts[0],
              gender: parts[1],
              age: parts[2],
              region: parts[3],
              stream: parts[4],
              pack: parts[5] || "",
            },
          };
        } else {
          return item;
        }
      });
    },
    completes() {
      return this.searchQuotaList
        .filter((item) => {
          const countUnderscores = (item.quotaName.match(/_/g) || []).length;
          return countUnderscores >= 3;
        })
        .reduce((sum, item) => sum + item.count, 0);
    },
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    parseProgress(progress) {
      return parseFloat(progress.replace("%", ""));
    },
    getDetails() {
      this.loading = true;
      const data = {
        wsName: "GetQuotaModuleDetails",
        data: {
          surveyId: this.surveyId,
        },
      };

      const getDetails = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getDetails.then((data) => {
        if (data.success === false) {
          this.$notification["error"]({
            message: "Error!",
            description: data.message,
            placement: "bottomLeft",
            duration: 4,
          });
        } else {
          this.quotaDetails = data;
        }
        this.loading = false;
      });
    },
    downloadQuotasExcel() {
      this.loadingExcel = true;
      const downloadQuotasList = new Promise((resolve) => {
        wsUtils.DownloadQuotasList({ surveyId: this.surveyId }, resolve);
      });

      downloadQuotasList.then((data) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.loadingExcel = false;
      });
    },
    manageSample() {
      window.open(this.quotaDetails.manageQuota, "_blank", "noreferrer");
    },
    addQuotaFile(data) {
      this.quotaFile = data.file;
    },
    uploadQuota() {
      this.loadingUpload = true;
      let form = new FormData();
      form.append("file", this.quotaFile);
      form.append("surveyId", this.surveyId);

      const product = {
        SurveyName: this.surveyName,
        SurveyID: this.surveyId,
        SurveyTypeName: this.surveyType,
      };

      const data = {
        wsName: "UploadQuotaList",
        data: {
          form: form,
        },
      };

      const uploadQuotaFile = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      uploadQuotaFile.then((data) => {
        if (data.success === false) {
          this.$notification["error"]({
            message: "Error!",
            description: data.message,
            placement: "bottomLeft",
            duration: 5,
          });
          this.trackEvent("update_quotas", "", product, 1, {
            success_action: false,
          });
        } else {
          this.$notification["success"]({
            message: "Success!",
            description: "Quotas uploaded successfully.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.quotaDetails = data;
          this.updateQuotas = false;
          this.trackEvent("update_quotas", "", product, 1, {
            success_action: true,
          });
        }
        this.quotaFile = null;
        this.loadingUpload = false;
      });
    },
    handleVisibleChange(visible) {
      this.dropdownVisible = visible;
    },
    selectFilter(value) {
      console.log("value", value);
      this.selectedFilters = value;
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style lang="scss" scoped>
.quota-list {
  position: relative;
  &__update-info {
    border-radius: 5px;
    background: #f4f7fe;
    padding: 8px 12px 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 0;
      font-weight: 500;
    }
    i {
      font-size: 20px;
      color: var(--blue);
    }
  }
  &__data-info {
    min-height: 610px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    position: relative;
    min-width: 200px;
    min-height: 27px;
  }
  &__statistics {
    gap: 15px;
    width: 148px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
  }
  &__statistics-item {
    position: relative;
    height: 93px;
    border-radius: 12px;
    padding: 11px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border-bottom: 2px solid #1abc9c;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.05);

    &--1 {
      border-color: #3498db;
    }
    &--2 {
      border-color: #e67e22;
    }
    &--3 {
      border-color: #e74c3c;
    }
    span {
      font-size: 26px;
      font-weight: 700;
      display: block;
      line-height: normal;
    }
    h2 {
      font-size: 14px;
      font-weight: 500;
      color: #8995ad;
      line-height: 18px;
      margin-top: 3px;
    }
  }
  &__statistics-item-loader {
    height: 35px;
    display: flex;
    align-items: center;
  }
  &__upload {
    position: absolute;
    inset: -10px;
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  &__upload-content {
    max-width: 380px;
    width: 100%;
    background: #fff;
    padding: 20px 25px 22px;
    border-radius: 12px;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.09);
    ::v-deep .ant-upload {
      padding: 0 20px;
      border-color: #68696a;
      border-radius: 12px;
      margin-bottom: 20px;
      height: 120px;
      .ant-upload-btn {
        border: 0;
      }
    }
    ::v-deep .ant-upload-drag-container {
      svg {
        font-size: 34px;
        color: #7e7e7e;
        margin-bottom: 12px;
        &.fa-check-circle {
          color: #1990ff;
        }
      }
      p {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}

::v-deep .ant-progress {
  .ant-progress-bg {
    background: var(--blue);
  }
  .ant-progress-inner {
    background: #e6e6e6;
  }
}
</style>
