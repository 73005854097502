<template>
  <div class="products body-bcg">
    <a-breadcrumb class="breadcrum">
      <a-breadcrumb-item
        ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item>Products</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="page-padding">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="33"
          :viewBox1="24"
          :viewBox2="24"
          iconName="products"
          iconColor="transparent"
          iconStroke="#4318FF"
          ><icon-products
        /></icon-base>
        <div class="page-title__text">
          <h1>Products</h1>
          <span>Explore Our Testing Solutions </span>
        </div>
      </div>

      <div class="box-wrapper max-w-970 mb-25">
        <Loader
          v-if="loading"
          :paddingTop="200"
          :paddingBottom="200"
          text="Loading products, please wait!"
          class="flex-1"
        ></Loader>
        <div v-else>
          <div class="section-info mb-35">
            <p>Click product below to get started!</p>
          </div>

          <div class="products__grid">
            <div
              class="products__item"
              @click="selectProduct(product)"
              v-for="product in readyProducts"
              :key="product.TypeId"
            >
              <div class="products__item-icon">
                <img :src="product.Icon" alt="product.name" />
              </div>
              <h2>
                {{ product.Name }}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="box-wrapper max-w-970">
        <Loader
          v-if="loading"
          :paddingTop="200"
          :paddingBottom="200"
          text="Loading products, please wait!"
          class="flex-1"
        ></Loader>
        <div v-else>
          <h3 class="section-title">Coming soon</h3>
          <div class="products__grid">
            <div
              class="products__item"
              :disabled="true"
              v-for="product in upcomingProducts"
              :key="product.TypeId"
            >
              <div class="products__item-icon">
                <img :src="product.Icon" alt="product.Icon" />
              </div>
              <h2>
                {{ product.Name }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import { mutations } from "@/utils/store.js";
import IconBase from "@/components/general/IconBase.vue";
import IconProducts from "@/components/icons/IconProducts.vue";
import Loader from "@/components/general/Loader.vue";
export default {
  name: "Products",
  components: {
    IconBase,
    IconProducts,
    Loader,
  },
  data() {
    return {
      client: {},
      user: null,
      products: [],
      projectID: 0,
      loading: false,
    };
  },
  computed: {
    readyProducts() {
      return this.products.filter((product) => product.Status === "1");
    },
    upcomingProducts() {
      return this.products.filter((product) => product.Status === "2");
    },
  },
  methods: {
    selectProduct(product) {
      switch (product.TypeId) {
        case 108:
          this.createMapMakerProject(product);
          break;
        case 8:
        case 97:
        case 98:
        case 99:
        case 101:
        case 102:
        case 103:
          this.createVidProject(product);
          break;
        case 2:
          this.$router.push({ name: "image-ads" });
          break;
        case 93:
        case 107:
          this.createPackTestProject(product);
          break;
        case 106:
          window.open(
            "https://www.hotspexmedia.com/contact",
            "_blank",
            "noreferrer"
          );
          break;
        default:
          break;
      }
    },
    createVidProject(product) {
      let routeName;
      switch (product.TypeId) {
        case 97:
          routeName = "comms-setting";
          break;
        case 98:
          routeName = "vid-ua-setting";
          break;
        case 99:
          routeName = "vid-yt-ua-setting";
          break;
        case 101:
          routeName = "vid-tiktok-setting";
          break;
        case 102:
          routeName = "vid-reticle-setting";
          break;
        case 103:
          routeName = "vid-tiktok-ua-setting";
          break;
        default:
          routeName = "vid-setting";
          break;
      }

      mutations.setSurveyPrice(product.TypeId, "SurveyType");
      const brandHelthType = product.TypeId === 92;
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyData1(
          {
            SurveyTypeId: product.TypeId,
            ProjectID: Number(this.projectID),
            UserID: window.userID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        if (data.Status === 0) {
          this.$notification["error"]({
            message: "Error!",
            description: "Something went wrong, please try again.",
            placement: "bottomLeft",
            duration: 5,
          });
        } else {
          let surveyPriceSetData = {
            SurveyId: data.SurveyId,
            SurveyType: product.TypeId,
            ClickSpex: true,
            Stims: brandHelthType ? [] : [{ duration: 100 }],
            Question: [],
            Audience: {
              sampleSize: 200,
              categoryUser: 0,
              genPop: 1,
            },
          };

          if (product.TypeId === 97) {
            surveyPriceSetData.ReportType = "Automated";
          } else {
            surveyPriceSetData.ReportType = "Base";
          }

          jsUtils.setSurveyTypeId(product.TypeId);
          jsUtils.setSurveyPrice(surveyPriceSetData);
          jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
          jsUtils.setRunSurveyMode(true);

          mutations.setSurveyPrice(data.SurveyId, "SurveyId");
          this.$router.push({
            name: routeName,
            params: { id: data.SurveyId },
          });
        }
      });
    },
    createPackTestProject(product) {
      mutations.setSurveyPrice(product.TypeId, "SurveyType");
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyPacktest(
          {
            SurveyTypeId: product.TypeId,
            ProjectID: Number(this.projectID),
            UserID: window.userID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        if (data.Status === 0) {
          this.$notification["error"]({
            message: "Error!",
            description: "Something went wrong, please try again.",
            placement: "bottomLeft",
            duration: 5,
          });
        } else {
          let surveyPriceSetData = {
            SurveyId: data.SurveyId,
            SurveyType: product.TypeId,
            ClickSpex: true,
            Stims: [],
            Question: [],
            ReportOption: "Automated",
            Audience: {
              sampleSize: 200,
              categoryUser: 0,
              genPop: 1,
            },
          };

          let packTestPriceSetData = {
            surveyId: data.SurveyId,
            explicitSamplePerDeepDive: 100,
            legs: 1,
            ReportOption: "Automated",
            implicitComponent: true,
            deepDive: 1,
            compDeepDive: 0,
            comObjects: 1,
          };

          const routeName =
            product.TypeId === 93
              ? "pack-test-setting"
              : "pack-sbd-test-setting";

          jsUtils.setSurveyTypeId(product.TypeId);
          jsUtils.setSurveyPrice(surveyPriceSetData);
          jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
          jsUtils.setRunSurveyMode(true);
          jsUtils.setPackTestPrice(packTestPriceSetData);

          mutations.setSurveyPrice(data.SurveyId, "SurveyId");
          this.$router.push({
            name: routeName,
            params: { id: data.SurveyId },
          });
        }
      });
    },
    createMapMakerProject(product) {
      mutations.setSurveyPrice(product.TypeId, "SurveyType");
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyData1(
          {
            SurveyTypeId: product.TypeId,
            ProjectID: Number(this.projectID),
            UserID: window.userID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        if (data.Status === 0) {
          this.$notification["error"]({
            message: "Error!",
            description: "Something went wrong, please try again.",
            placement: "bottomLeft",
            duration: 5,
          });
        } else {
          let surveyPriceSetData = {
            SurveyId: data.SurveyId,
            SurveyType: product.TypeId,
            ClickSpex: true,
            Stims: [],
            Question: [],
            ReportOption: "Automated",
            Audience: {
              sampleSize: 200,
              categoryUser: 0,
              genPop: 1,
            },
          };

          jsUtils.setSurveyTypeId(product.TypeId);
          jsUtils.setSurveyPrice(surveyPriceSetData);
          jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
          jsUtils.setRunSurveyMode(true);
          mutations.setSurveyPrice(data.SurveyId, "SurveyId");

          this.$router.push({
            name: "map-maker-settings",
            params: { id: data.SurveyId },
          });
        }
      });
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.projectID = parseInt(sessionStorage.getItem("pID"));
    this.loading = true;
    const tyepPromise = new Promise((resolve) => {
      wsUtils.SurveyTypeFilter(
        {
          accountId: this.user.EncryptedAccountId,
        },
        resolve
      );
    });

    tyepPromise.then((data) => {
      this.products = data;
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.products {
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  &__item {
    background: #fff;
    height: 100%;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    transition: all 0.25s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 20px 18px;
    cursor: pointer;
    &:hover {
      border-color: var(--red);
    }
    &[disabled] {
      opacity: 0.6;
      pointer-events: none;
      .products__item-icon {
        background: #a3a3a3;
      }
    }
    h2 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.4px;
    }
  }
  &__item-icon {
    width: 140px;
    height: 140px;
    border-radius: 100px;
    background: linear-gradient(180deg, #4318ff 33.09%, #ffffff 225.2%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 34px;
    img {
      max-width: 70%;
    }
  }
}
// h3 {
//   margin: 30px auto 60px;
//   font-size: 1.6em;
// }

// h4 {
//   margin: 60px 20px;
//   position: relative;

//   hr {
//     margin: 1.3em;
//     position: absolute;
//     left: 20px;
//     right: 20px;
//     z-index: -1;
//   }

//   p {
//     font-size: 1.8em;
//     text-align: center;

//     span {
//       background-color: white;
//       padding: 0 2em;
//       color: var(--iconBGGrey);
//     }
//   }
// }

// .productGrids {
//   display: flex;
//   column-gap: 30px;
//   justify-content: center;
//   flex-wrap: wrap;
//   .icon {
//     border-radius: 100%;

//     img {
//       max-width: 100%;
//       transition: transform 0.3s;
//     }
//   }
// }

// .ready {
//   column-gap: 50px;
//   @media screen and (max-width: 980px) {
//     column-gap: 30px;
//   }
//   .icon {
//     background-color: var(--hsblue);
//     width: 240px;
//     height: 240px;
//     padding: 10%;
//     cursor: pointer;
//     overflow: hidden;
//     @media screen and (max-width: 980px) {
//       width: 200px;
//       height: 200px;
//     }
//   }

//   .icon:hover {
//     img {
//       transform: scale(0.9);
//     }

//     box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.4);
//     background-color: var(--hsblueDark);
//   }
// }

// .upcoming {
//   column-gap: 40px;

//   .icon {
//     background-color: var(--lineGrey);
//     width: 150px;
//     height: 150px;

//     padding: 15%;
//     cursor: default;
//   }
// }
</style>
