<template>
  <div class="shelf-builder-tools">
    <div class="shelf-builder-tools__breadcrumb">
      <router-link to="/shelf-builder-library"> Project List</router-link>/
      {{ projectName }}
    </div>

    <div class="flex gap-10 shelf-builder-tools__cta">
      <a-button
        type="dark"
        @click="$emit('change-currency')"
        class="csv-options flex items-center gap-2"
        ><icon-base
          :width="22"
          :height="22"
          :viewBox1="273"
          :viewBox2="273"
          iconName="currency"
          iconColor="#fff"
          ><icon-currency
        /></icon-base>
        Currency
      </a-button>
      <a-dropdown :trigger="['click']">
        <a-button type="dark" @click.prevent class="csv-options"
          ><font-awesome-icon icon="file" /> Import/Export
        </a-button>
        <template #overlay>
          <a-menu class="dark-dropdown-menu">
            <a-menu-item key="0">
              <a @click="uploadNewCSV('PSA', '.zip')">Upload a new PSA</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a @click="uploadNewCSV('CSV', '.csv')">Upload a new CSV</a>
            </a-menu-item>
            <a-menu-item key="2">
              <a @click="$emit('download-csv')"
                >Export .xlsx for {{ activeShelf }}</a
              >
            </a-menu-item>
            <a-menu-item key="3" v-if="false">
              <a @click="$emit('download-csv-all')"
                >Export .xlsx for all tests</a
              >
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

      <a-button
        type="dark"
        @click="$emit('upload')"
        class="save flex justify-center items-center b-radius-40"
        ><a-icon type="edit" /> Update</a-button
      >

      <a-button
        type="primary"
        @click="!disableSaveBtn ? $emit('save') : null"
        :disable="disableSaveBtn || saveDataLoading"
        class="save flex justify-center items-center b-radius-40"
        ><Loader text="" v-if="saveDataLoading"></Loader
        ><span v-else><a-icon type="save" class="mr-5" /> Save</span></a-button
      >
    </div>
  </div>
</template>

<script>
import Loader from "@/components/general/Loader.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconCurrency from "@/components/icons/IconCurrency.vue";

export default {
  name: "Tools",
  components: { Loader, IconBase, IconCurrency },
  props: {
    loading: {
      type: Boolean,
    },
    saveDataLoading: {
      type: Boolean,
    },
    disableSaveBtn: {
      type: Boolean,
    },
    projectName: {
      type: String,
    },
    activeShelf: {
      type: String,
    },
    allShelfLength: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    uploadNewCSV(label, type) {
      let _this = this;
      this.$confirm({
        title: `Upload a new ${label}`,
        content: () => (
          <div>
            Are you sure you want to upload a new file? All unsaved changes will
            be deleted.
          </div>
        ),
        onOk() {
          _this.$emit("open-upload-modal", {
            fileType: type,
            fileLabel: label,
          });
        },
        okText: "Yes",
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shelf-builder-tools {
  display: flex;
  padding: 15px 20px;
  gap: 10px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 96px;
  right: 0;
  z-index: 99;
  border-bottom: 1px solid #262627;
  box-shadow: 0 0 19px 20px rgba(0, 0, 0, 0.15);
  background: #1a1b1d;
  &.disabled {
    .shelf-builder-tools__cta {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .ant-btn {
    svg {
      margin-right: 8px;
    }
    &.csv-options {
      svg {
        color: #a9a9a9;
      }
    }
    &[disable] {
      opacity: 0.4;
      cursor: default;
    }
    &.save {
      min-width: 105px;
      ::v-deep .circle-loader {
        margin-right: 0;
      }
    }
  }
  &__breadcrumb {
    color: #fff;
    font-size: 13px;
    display: flex;
    gap: 4px;
    align-items: center;
    a {
      color: var(--purpleLight);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
