<template>
  <div
    class="redistribute-modal"
    ref="redistributeModal"
    @mousedown="startDrag"
  >
    <div class="redistribute-modal__title flex items-center justify-between">
      <h2 class="flex items-center gap-5">
        Redistribute

        <a-tooltip overlayClassName="ant-tooltip--step-nav">
          <template slot="title">
            Move this modal to where it suits you best.
          </template>
          <a-icon type="info-circle" class="cursor-pointer" />
        </a-tooltip>
      </h2>
      <div @click="$emit('close')" class="cursor-pointer flex">
        <icon-base
          :width="18"
          :height="18"
          :viewBox1="1024"
          :viewBox2="1024"
          iconName="close"
          iconColor="#fff"
          iconStroke="transparent"
          ><icon-close
        /></icon-base>
      </div>
    </div>
    <div class="redistribute-modal__inner">
      <div class="flex gap-10 mb-10">
        <a-switch v-model="spaceLeftEnabled" @click.native.stop /> Enable Left
        Space
      </div>
      <div class="flex gap-10">
        <a-switch v-model="spaceRightEnabled" @click.native.stop />
        Enable Right Space
      </div>
      <div class="mt-20">
        <label> Space Adjustment </label>
        <div class="flex items-center justify-center">
          <a-slider
            v-model="spaceAdjustment"
            :min="1"
            :max="50"
            class="flex-1"
            :tooltipVisible="false"
          />
          <span class="ml-5">{{ spaceAdjustment }}px</span>
        </div>
      </div>
      <div class="flex justify-between items-center gap-10 mt-20">
        <a-button
          type="dark"
          class="flex-1 flex items-center justify-center gap-5"
          @click.stop="
            $emit(
              'add-products-space',
              'increase',
              spaceLeftEnabled,
              spaceRightEnabled,
              spaceAdjustment
            )
          "
          :disabled="!spaceLeftEnabled && !spaceRightEnabled"
          ><icon-base
            :width="14"
            :height="14"
            :viewBox1="24"
            :viewBox2="24"
            iconName="plus"
            iconStroke="transparent"
            ><icon-plus /></icon-base
          >Increase</a-button
        >
        <a-button
          type="dark"
          class="flex-1 flex items-center justify-center gap-5"
          @click.stop="
            $emit(
              'add-products-space',
              'decrease',
              spaceLeftEnabled,
              spaceRightEnabled,
              spaceAdjustment
            )
          "
          :disabled="!spaceLeftEnabled && !spaceRightEnabled"
        >
          <icon-base
            :width="14"
            :height="14"
            :viewBox1="24"
            :viewBox2="24"
            iconName="minus"
            iconStroke="transparent"
            ><icon-minus
          /></icon-base>
          Decrease</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconClose from "@/components/icons/IconClose.vue";
import IconMinus from "@/components/icons/IconMinus.vue";
import IconPlus from "@/components/icons/IconPlus.vue";

export default {
  name: "RedistributeModal",
  components: {
    IconBase,
    IconClose,
    IconPlus,
    IconMinus,
  },
  props: {},
  data() {
    return {
      spaceLeftEnabled: true,
      spaceRightEnabled: true,
      isDragging: false,
      offset: { x: 0, y: 0 },
      spaceAdjustment: 1,
    };
  },
  computed: {},
  watch: {},
  methods: {
    startDrag(event) {
      this.isDragging = true;
      this.offset.x = event.clientX - this.$refs.redistributeModal.offsetLeft;
      this.offset.y = event.clientY - this.$refs.redistributeModal.offsetTop;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        const div = this.$refs.redistributeModal;
        div.style.left = `${event.clientX - this.offset.x}px`;
        div.style.top = `${event.clientY - this.offset.y}px`;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.redistribute-modal {
  background-color: #262729;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: move;
  z-index: 999;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.3);
  user-select: none;
  &__title {
    padding: 16px 24px;
    border-bottom: 1px solid #4b4e52;
    h2 {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
  &__content {
    padding: 20px;
  }
  &__inner {
    padding: 24px;
    color: #fff;
    font-size: 14px;
  }
}
</style>
