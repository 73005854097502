var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quota-list"},[(_vm.loading)?_c('div',{staticClass:"quota-list__data-info"},[_c('Loader',{attrs:{"text":"Loading..."}})],1):_c('div',[_c('div',{staticClass:"quota-list__table flex-1"},[_c('a-table',{staticClass:"custom-table custom-table--remove-hover",attrs:{"tableLayout":"auto","columns":_vm.columns,"row-key":function (record, index) { return ("" + (record.surveyNumber)); },"data-source":_vm.quotaDetails.SurveyData,"loading":_vm.loading,"pagination":_vm.pagination},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"Status",fn:function(record){return _c('div',{staticClass:"input-group input-group--small"},[_c('a-select',{key:((record.surveyNumber) + "-" + _vm.uniqueCounter),class:{
              'pointer-events-none':
                _vm.activeUpdateStatus === record.surveyNumber,
            },staticStyle:{"width":"130px"},attrs:{"option-filter-prop":"children","default-value":_vm.getStatusName(record.surveyStatusCode),"loading":_vm.activeUpdateStatus === record.surveyNumber},on:{"select":function($event){return _vm.updateStatus($event, record)}}},_vm._l((_vm.statuses),function(status){return _c('a-select-option',{key:status.statusId,attrs:{"value":status.statusId,"disabled":status.statusId == record.surveyStatusCode}},[_vm._v(" "+_vm._s(status.statusName)+" ")])}),1)],1)}},{key:"Tools",fn:function(record){return _c('div',{staticClass:"flex items-center justify-end gap-10"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Edit ")]),_c('button',{staticClass:"text-btn text-btn--dark text-left",attrs:{"type":"link"},on:{"click":function($event){return _vm.editQuota(record)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)],2),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Copy ")]),_c('button',{staticClass:"text-btn text-btn--dark text-left flex items-center",class:{
                'pointer-events-none':
                  _vm.activeCopyQuota === record.surveyNumber,
              },attrs:{"type":"link"},on:{"click":function($event){return _vm.copy(record)}}},[(_vm.activeCopyQuota === record.surveyNumber)?_c('Loader',{staticClass:"small-loader space-0"}):_c('font-awesome-icon',{attrs:{"icon":"copy"}})],1)],2),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Delete ")]),_c('button',{staticClass:"text-btn text-btn--dark text-left flex items-center",class:{
                'pointer-events-none':
                  _vm.activeDeleteQuota === record.surveyNumber,
              },attrs:{"type":"link"},on:{"click":function($event){return _vm.deleteQuotaConfirm(record)}}},[(_vm.activeDeleteQuota === record.surveyNumber)?_c('Loader',{staticClass:"small-loader space-0"}):_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1)],2)],1)}}])})],1)]),_c('a-modal',{attrs:{"title":"PO Entry","visible":_vm.poModal,"footer":null,"width":400,"centered":""},on:{"cancel":function($event){(_vm.poModal = false), (_vm.po = null), _vm.uniqueCounter++}}},[_c('p',{staticClass:"mb-20"},[_vm._v(" All field costs must now be approved before field can commence. Please submit a PO via "),_c('a',{staticClass:"underline",attrs:{"href":"https://app.smartsheet.com/b/form/6a2fc8502c2946fa849aa2db327d6559","target":"_blank"}},[_vm._v("this link")]),_vm._v(" with your estimated costs (sample * CPI, keeping in mind that CPI is in USD)."),_c('br'),_vm._v("Once your request is approved, you will receive a unique PO ID by email that you can enter in the field below. ")]),_c('div',{staticClass:"flex items-center position-r"},[_c('div',{staticClass:"input-group input-group--small mb-15 flex-1"},[_c('label',[_vm._v("PO ID "),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" To validate the PO, please enter a value and either click outside the field or press Enter. ")]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('a-input',{attrs:{"placeholder":"Enter PO ID"},on:{"blur":_vm.checkPo,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkPo.apply(null, arguments)}},model:{value:(_vm.po),callback:function ($$v) {_vm.po=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"po"}})],1),_c('div',{staticClass:"position-a left-full mt-10 ml-5"},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_vm._v(" PO is valid ")]),(
              this.lastValidPo === this.po &&
              this.po !== null &&
              this.lastValidPo !== null
            )?_c('font-awesome-icon',{staticClass:"font-size-16 cursor-pointer",staticStyle:{"color":"#01c101"},attrs:{"icon":"check-circle"}}):_vm._e()],2),(_vm.loadingCheckPo)?_c('Loader',{staticClass:"small-loader"}):_vm._e()],1)]),_c('div',{staticClass:"flex justify-end gap-10"},[_c('a-button',{staticClass:"mt-20",attrs:{"type":"default"},on:{"click":function($event){(_vm.poModal = false), (_vm.po = null), _vm.uniqueCounter++}}},[_vm._v("Close")]),_c('a-button',{staticClass:"mt-20",attrs:{"loading":_vm.submitPOLoading,"type":"primary","disabled":!_vm.po || _vm.lastValidPo !== _vm.po},on:{"click":_vm.submitPO}},[_vm._v("Submit")])],1)]),_c('a-modal',{attrs:{"title":"Quota Definition","visible":_vm.editModal,"footer":null,"width":800,"centered":""},on:{"cancel":function($event){_vm.editModal = false}}},[(_vm.editModal)?_c('EditQuotaModal',{attrs:{"activeQuota":_vm.activeQuota,"surveyCells":_vm.surveyCells},on:{"close":_vm.closeEditQuotaModal,"send-gtag":function($event){return _vm.sendGtag('update')}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }