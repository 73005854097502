<template>
  <div class="page-loader-wrapper flex items-center justify-center">
    <i class="circle-loader circle-loader--page-loader"></i>
  </div>
</template>

<script>
export default {
  name: "PageLoader",
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.page-loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>
