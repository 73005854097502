<template>
  <div
    class="popover"
    ref="item-popover"
    :class="{
      'popover--right': selectedProduct.popoverPosition.class === 'right',
      'popover--left': selectedProduct.popoverPosition.class === 'left',
      'popover--top': selectedProduct.popoverPosition.class === 'top',
    }"
    :style="{
      left: selectedProduct.popoverPosition.left + 'px',
      top: selectedProduct.popoverPosition.top + 'px',
    }"
  >
    <div
      class="popover__close"
      @click="$emit('reset-product-popover-visible')"
    ></div>

    <div class="popover__row">
      <div class="large-field">
        <label>Facing Width</label>
        <a-input-number
          size="large"
          :min="1"
          :max="100000"
          v-model="facingWidth"
          @change="$emit('update-product-facing')"
          class="fullWidth"
        />
      </div>
      <div class="large-field">
        <label>Facing Height</label>
        <a-input-number
          size="large"
          :min="1"
          :max="100000"
          v-model="facingHeight"
          @change="$emit('update-product-facing')"
          class="fullWidth"
        />
      </div>
    </div>
    <div class="popover__row popover__row--check justify-between">
      <a-checkbox v-model="deepDive">Select for deep dive</a-checkbox>
    </div>
    <div class="popover__row popover__row--cta">
      <div class="popover__cta">
        <a-tooltip placement="top">
          <template
            slot="title"
            v-if="copiedSizeProductUPC !== selectedProduct.upc"
          >
            {{ selectedProduct.productWidth }}px x
            {{ selectedProduct.productHeight }}px</template
          >

          <span
            class="popover__cta-label"
            v-if="copiedSizeProductUPC !== selectedProduct.upc"
            @click="$emit('copy-product-size')"
          >
            Copy size</span
          >
          <span
            v-else
            class="popover__cta-label"
            :class="{ disabled: copiedSizeProductUPC === selectedProduct.upc }"
            ><font-awesome-icon class="font-size-13 mr-5" icon="check" />Size
            copied</span
          >

          <a-icon
            type="close"
            class="ml-5 font-size-13"
            v-if="copiedSizeProductUPC === selectedProduct.upc"
            @click="$emit('reset-size-copied')"
          />
        </a-tooltip>
      </div>
      <a-button type="primary" @click="$emit('show-product-preview')">
        Product details</a-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Popover",
  props: {
    selectedProduct: {
      type: Object,
    },
    selectedProductFacingWidth: {
      type: Number,
    },
    selectedProductFacingHeight: {
      type: Number,
    },
    copiedSizeProductUPC: {
      type: Number,
    },
    inDeepDive: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    facingWidth: {
      get() {
        return this.selectedProductFacingWidth;
      },
      set(newValue) {
        this.$emit("update-facing-width", newValue);
      },
    },
    facingHeight: {
      get() {
        return this.selectedProductFacingHeight;
      },
      set(newValue) {
        this.$emit("update-facing-height", newValue);
      },
    },
    deepDive: {
      get() {
        return this.inDeepDive;
      },
      set(newValue) {
        this.$emit("update-deep-dive", newValue);
      },
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.popover {
  position: absolute;
  background: #fff;
  z-index: 999;
  padding: 20px 26px 0;
  border-radius: 18px;
  box-shadow: 0 9px 15px 1px rgba(0, 0, 0, 0.45);
  width: 300px;
  &:after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -8px);
    top: 0;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
  &--top {
    margin: 0 0 10px 0;
    &:after {
      transform: translate(-50%, 0px) rotate(180deg);
      top: 100%;
    }
  }
  &--left {
    margin: 0 10px 0 0;
    &:after {
      left: 100%;
      transform: translate(-5px, -50%) rotate(90deg);
      top: 50%;
    }
  }
  &--right {
    margin: 0 0 0 10px;
    &:after {
      left: auto;
      right: 100%;
      transform: translate(4px, -50%) rotate(-90deg);
      top: 50%;
    }
  }
  .large-field {
    margin-bottom: 0;
    label {
      font-size: 13px;
      margin-bottom: 7px;
    }
    .ant-input-number-lg {
      height: 36px;
      border-color: #e8ecef;
      border-radius: 8px;
      overflow: hidden;
      ::v-deep input {
        height: 34px;
      }
      .ant-input-number-handler-wrap {
        border-left: 1px solid #e8ecef;
      }

      .ant-input-number-handler-down {
        border-top: 1px solid #e8ecef;
      }
    }
  }

  &__row {
    display: flex;
    gap: 14px;
    margin-bottom: 5px;
    &:last-child {
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &--check {
      margin-bottom: 23px;
    }
    &--cta {
      gap: 0;
      border-bottom-right-radius: 18px;
      overflow: hidden;
      border-bottom-left-radius: 18px;
      margin: 0px -26px 0;
    }
    ::v-deep .ant-btn {
      height: 44px;
      padding: 0 2px;
      flex: 1;
      border-radius: 0;
      text-shadow: none;
    }
  }

  &__cta {
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
    flex: 1;
    position: relative;
    transition: 0.3s ease all;
    &:hover {
      background: #e4e4e4;
    }
    svg {
      margin-right: 0;
    }
    svg[data-icon="check"] {
      color: #289060;
    }
    &[disabled] {
      opacity: 0.8;
      pointer-events: none;
      cursor: default;
    }
    i {
      position: absolute;
      right: 8px;
      top: 15px;
      z-index: 1;
    }
  }
  &__cta-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      text-decoration: none;
    }
  }
  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    z-index: 1;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
</style>
