<template>
  <div>
    <ShelfPreview :shelfID="$route.query.shelfID"></ShelfPreview>
  </div>
</template>

<script>
import ShelfPreview from "@/components/general/ShelfPreview.vue";

export default {
  name: "ShelfApp",
  components: { ShelfPreview },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
