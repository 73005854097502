<template>
  <div class="add-product">
    <div
      class="add-product__image mb-15"
      :class="{ loading: uploadProductImagesLoading }"
    >
      <a-upload-dragger
        name="file"
        accept="image/jpeg, image/png, image/jpg"
        :multiple="false"
        :showUploadList="false"
        :customRequest="uploadProductImage"
        ref="upload"
      >
        <div>
          <font-awesome-icon icon="file-upload" v-if="image === null" />
          <font-awesome-icon icon="check-circle" v-else />
          <p>Drop image here or click to upload</p>
        </div>
      </a-upload-dragger>
    </div>
    <div class="add-product__info">
      <div>
        <div class="large-field large-field--dark w-full">
          <label>*Product name</label>
          <a-input class="ant-input--dark w-full" v-model="productName" />
        </div>
        <div class="flex gap-10">
          <div class="large-field large-field--dark w-full">
            <label>Price</label>
            <a-input-number
              :min="0"
              class="ant-input--dark w-full"
              v-model="price"
            />
          </div>
          <div class="large-field large-field--dark w-full">
            <label>*UPC</label>
            <a-input class="ant-input--dark w-full" v-model="upc" />
          </div>
        </div>
        <div class="flex gap-10">
          <div class="large-field large-field--dark w-full">
            <label>Weight</label>
            <a-input class="ant-input--dark w-full" v-model="weight" />
          </div>
          <div class="large-field large-field--dark w-full">
            <label class="flex-i justify-between"
              >*Brand

              <a-tooltip placement="left">
                <template slot="title">
                  Select a brand from the dropdown menu or type a new brand and
                  press Enter to add it.
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <a-select
              show-search
              @change="selectBrand"
              mode="tags"
              :max-tag-count="1"
              :max-tag-text-length="16"
              :open="brandDropdownOpen"
              @dropdownVisibleChange="handleDropdownVisibility"
              v-model="brand"
              class="ant-select--dark ant-select--single-tag w-full"
              dropdownClassName="dark"
            >
              <a-select-option
                v-for="brand in uniqueBrandNames"
                :key="brand"
                :value="brand"
              >
                {{ brand }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="flex gap-10">
          <div class="large-field large-field--dark w-full">
            <label>*Image height</label>
            <a-input-number
              :min="0"
              class="ant-input--dark w-full"
              v-model="imageHeight"
            />
          </div>
          <div class="large-field large-field--dark w-full">
            <label>*Image width</label>
            <a-input-number
              :min="0"
              class="ant-input--dark w-full"
              v-model="imageWidth"
            />
          </div>
        </div>
      </div>

      <p class="text-white font-size-13">*Required fields</p>

      <div class="add-product__info-footer gap-10">
        <a-button @click="$emit('close-add-product')">Cancel</a-button>
        <a-button
          type="primary"
          :loading="loading"
          @click="save"
          :disabled="saveDisabled"
          >Save</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
export default {
  name: "AddNewProduct",
  props: {
    shelfType: {
      type: String,
    },
    shelfId: {
      type: String,
    },
    uniqueBrandNames: {
      type: Array,
    },
  },
  data() {
    return {
      editName: false,
      uploadProductImagesLoading: false,
      image: null,
      productName: "",
      price: "",
      upc: "",
      weight: "",
      brand: undefined,
      imageWidth: "",
      imageHeight: "",
      loading: false,
      brandDropdownOpen: false,
    };
  },
  computed: {
    saveDisabled() {
      return (
        this.image === null ||
        this.productName === "" ||
        this.upc === "" ||
        this.brand === undefined ||
        this.imageWidth === "" ||
        this.imageHeight === ""
      );
    },
  },
  watch: {},
  methods: {
    uploadProductImage(data) {
      this.image = data.file;
    },
    generateRandomNumber(length) {
      return Math.floor(Math.random() * Math.pow(10, length))
        .toString()
        .padStart(length, "0");
    },
    save() {
      this.loading = true;
      if (this.price === "") this.price = null;
      let form = new FormData();
      form.append("file", this.image);
      form.append("surveyId", this.shelfId);
      form.append("shelfType", this.shelfType);
      form.append("productName", this.productName);
      form.append("price", this.price);
      form.append("upc", this.upc);
      form.append("weight", this.weight);
      form.append("brand", this.brand);
      form.append("imageWidth", this.imageWidth);
      form.append("imageHeight", this.imageHeight);
      form.append("shelfPosition", 1);
      form.append("bayPosition", 1);
      form.append("productPosition", 1);

      const uploadShelfImages = new Promise((resolve) => {
        wsUtils.UploadShelfImages(
          {
            wsName: "AddShelfProduct",
            form,
          },
          resolve
        );
      });
      uploadShelfImages
        .then((data) => {
          if (data.success) {
            const productData = {
              productPosition: 1,
              upc: `${this.upc}${this.generateRandomNumber(8)}`,
              weight: this.weight,
              productURL: data.imageURL,
              thumbProductURL: data.ThumbImageURL,
              rotated_image_url: "",
              thumb_rotated_image_url: "",
              productName: this.productName,
              price: this.price,
              brandName: this.brand,
              productWidth: this.imageWidth,
              productHeight: this.imageHeight,
              totalFacing: 1,
              widthFacing: 1,
              heightFacing: 1,
              X1: 0,
              y1: 0,
              x2: 0,
              y2: 0,
              showPrice: true,
              inDeepDive: false,
              stack: [],
              spaceLeft: 0,
              spaceRight: 0,
            };

            this.$emit("add-product", productData);
            this.$notification["success"]({
              message: "Product Added",
              description: `The product "${this.productName}" has been added successfully!`,
              placement: "bottomLeft",
              duration: 7,
            });
          } else {
            this.handleError(data, "Upload Shelf Image");
          }
          this.loading = false;
          this.resetFields();
        })
        .catch((error) => {
          this.handleError(error, "Upload Shelf Image");
        });
    },
    handleError(error, errorTitle) {
      let message =
        "An error has occurred. Please try again or contact support.";
      if (error && error.status === 500) {
        message = "Server error. Please try again later or contact support.";
      }

      this.$notification["error"]({
        message: errorTitle,
        description: message,
        placement: "bottomLeft",
        duration: 5,
      });
    },
    resetFields() {
      this.image = null;
      this.productName = "";
      this.price = "";
      this.upc = "";
      this.weight = "";
      this.brand = undefined;
      this.imageWidth = "";
      this.imageHeight = "";
    },
    selectBrand(brand) {
      if (brand.length > 0) {
        this.brandDropdownOpen = false;
      }
      if (!brand || (Array.isArray(brand) && brand.length === 0)) {
        return;
      }
      if (Array.isArray(brand) && brand.length > 1) {
        brand = [brand.pop()];
      }
      const newBrand = brand[0] ?? brand;

      this.brand = newBrand;
    },
    handleDropdownVisibility(visible) {
      this.brandDropdownOpen = visible;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-product {
  gap: 15px;
  &__image {
    width: 100%;
    height: 150px;
    position: relative;
    ::v-deep .ant-upload {
      background: #1f2022;
      color: #dcd9d9;
      padding: 0 20px;
      border-color: #68696a;
      border-radius: 12px;
      margin-bottom: 20px;
      .ant-upload-btn {
        border: 0;
      }
    }
    ::v-deep .ant-upload-drag-container {
      svg {
        font-size: 34px;
        color: #7e7e7e;
        margin-bottom: 12px;
        &.fa-check-circle {
          color: #1990ff;
        }
      }
      p {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  &__info {
    color: #d1d1d1;
    textarea.ant-input {
      font-size: 18px;
      font-weight: 400;
      border: 1px solid #434344;
      line-height: normal;
      padding: 4px 6px;
      margin: 0 -6px 15px;
    }
  }

  &__info-footer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: flex-end;
  }

  ::v-deep .ant-input-number {
    padding: 0;
    outline: 0;
    box-shadow: none;
    &.ant-input-number-focused {
      border-color: #434344;
    }
    .ant-input-number-handler-wrap {
      background: #434344;
      border-left: 1px solid #404144;
      opacity: 1;
      .ant-input-number-handler-up-inner,
      .ant-input-number-handler-down-inner {
        color: #fff;
      }
      .ant-input-number-handler-down {
        border-top: 1px solid #5a5b5f;
      }
    }
  }

  ::v-deep .ant-select {
    .ant-select-selection__choice {
      animation: none;
      transition: 0s;
    }
    .zoom-leave {
      animation: none;
    }
  }
}
</style>
